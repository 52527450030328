import {useEffect, useRef, useState} from 'react';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export default function SplitButton({options, variant, color, sx, disabled, ...rest}) {
  const [open, setOpen] = useState(false);
  const actionRef = useRef(null);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  useEffect(() => {
    selectedIndex > (options.length - 1) && setSelectedIndex(0);
  }, [selectedIndex, options.length]);



  console.log({ sx, rest, selectedIndex, options }, 'splitbutton');

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        {...{variant, color, sx, disabled}}
        aria-label="split button"
      >
        <Button
          onClick={options[selectedIndex]?.onClick}
          disabled={disabled}
          sx={sx}
          startDecorator={options[selectedIndex]?.startDecorator}>
          {options[selectedIndex]?.label}
        </Button>
        {options.length > 1 && <IconButton

          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onMouseDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onKeyDown={() => {
            actionRef.current = () => setOpen(!open);
          }}
          onClick={() => {
            actionRef.current?.();
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
        }
      </ButtonGroup>
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            disabled={index === 2}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
