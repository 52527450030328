import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SplitButton from '../common/SplitButton';
import DeployModal from './DeployModal';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function LocalFiles({ getData, setData, sx, setError }) {

  const [fileName, setFileName] = useState('');
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [deployOpen, setDeployOpen] = useState(false);

  const handleDeploy = () => {
    setDeployOpen(true);
  };


  const handleSaveFile = () => {
    if (window.showSaveFilePicker) {
      window.showSaveFilePicker?.call(window, {
        suggestedName: "my-agent.json"
      }).then(async (fileHandle) => {
        const file = await fileHandle.createWritable();
        await file.write(JSON.stringify(getData(), null, 2));
        await file.close();
        setShowFilePicker(false);
      }
      ).catch(err => {
        console.error({ err }, err.message);
        setError(`File not saved: ${err.message}`);
      });
    }
    else {
      setShowFilePicker(true);
    }
  };

  const handleSave = () => {
    const jsonData = JSON.stringify(getData(), null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName || 'data'}.json`;
    link.click();
    URL.revokeObjectURL(url);
    setShowFilePicker(false);
  };

  const handleUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const jsonData = e.target.result;
        const data = JSON.parse(jsonData);
        setData(data);
        setShowFilePicker(false);
      };
      reader.readAsText(file);
    };
    input.click();
  };




  return showFilePicker ? (
    <>
      <Input
        type="text"
        placeholder="Enter file name"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <Button variant="solid" onClick={handleSave}>Save</Button>
    </>
  ) : (<>
    <Box sx={{ ...sx, display: 'flex', mr: 0, ml: 0 }} spacing={1}>

      <Button
        variant="outlined"
        startDecorator={<FileUploadIcon />}
        onClick={handleUpload}
        sx={{ ml: 0, mr: 1, width: '100%' }}
      >
        Load
      </Button>

      <SplitButton
        variant="outlined"
        color="primary"
        options={[
          { label: 'Save', startDecorator: <FileDownloadIcon />, onClick: handleSaveFile },
          { label: 'Deploy', startDecorator: <CheckCircleOutlineIcon />, onClick: handleDeploy }
        ]}
        sx={{ mr: 0, ml: 1, width: '100%' }}
      />
      <DeployModal open={deployOpen} setOpen={setDeployOpen} />
    </Box>
  </>
  );

};

