import { useState, useEffect } from 'react';
import Select from '@mui/joy/Select';
import Grid from '@mui/joy/Grid';
import Option from '@mui/joy/Option';
import LanguageIcon from '@mui/icons-material/Language';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Tooltip from '../common/Tooltip';
import SelectGroupedOptions from '../common/GroupSelect';

const buildVoiceOptions = (handler, language, voices) => (handler && voices && Object.fromEntries(
  Object.entries(voices[handler]).map(
    ([provider, languages]) =>
    ([provider,
      languages[language]?.map(voice => ({ ...voice, description: `${voice.description} (${voice.gender})` }))
    ])
  )
));

// Is the voice one of the currently available voices?
const haveVoice = (voiceOptions, voice) => voiceOptions && voice && Object.values(voiceOptions).some(a => Array.isArray(a) && a.some(v => v?.name === voice));

export default function SelectVoice({
  disabled,
  language,
  modelName,
  setLanguage,
  defaultLanguage,
  voice,
  setVoice,
  voices,
  tooltip
}) {
  let [languages, setLanguages] = useState();
  let [voiceOptions, setVoiceOptions] = useState();
  let [handler] = modelName?.split(':') || [];

  
  
  useEffect(() => {
    if (language && voices) {
      let options = buildVoiceOptions(handler, language, voices) || {};
      if (Object.keys(options).length) {
        setVoiceOptions(options);
      }
      else {
        setLanguage(null);
      }
      console.log({ voices, language, options }, 'SelectVoice');
    }
    handler && voices?.[handler] && setLanguages(Object.keys(Object.values(voices[handler]).reduce((o, v) => ({ ...o, ...v }), {})));
  }, [language, setLanguage, voices, handler, defaultLanguage]);

  useEffect(() => {
    if (languages?.length && !language) {
      setLanguage(languages.find(l => l === defaultLanguage) || languages[0]);
    }
  }, [languages, language, defaultLanguage, setLanguage]);

  useEffect(() => {

    // voiceOptions can change asynchronously at any time as they can be a function of the selected agent 
    // This effect actions the case where either an initial voice has not been set, or there is a voice but
    // it is invalid because it is not available when using the currently selected agent.
    let [, voiceName] = voice?.split(':') || [0, voice];
    if (voiceOptions && Object.keys(voiceOptions).length && (!voice || !haveVoice(voiceOptions, voiceName))) {
      let [provider, pVoices] = Object.entries(voiceOptions)?.[0] || []
      let def = pVoices?.find(l => l === defaultLanguage)?.name;
      let first = pVoices?.[0]?.name;
      let voiceString = provider && (def || first) && `${provider}:${def || first}`
      setVoice(voiceString);
 
    }
  }, [voice, voices, handler, voiceOptions, defaultLanguage, setVoice]);

  return (

    <Grid container sx={{ width: '100%' }}>
      <Grid xs={12} sm={4}>
        <Select
          value={language}
          disabled={disabled}
          placeholder="select language"
          label="language"
          startDecorator={<LanguageIcon />}
          onChange={(e, value) => setLanguage(value)} sx={{ m: 2 }}>
          {languages?.length && languages.map(option => (
            <Option key={option} value={option}>{`${option}`}</Option>
          ))}
        </Select>
      </Grid>
      <Grid xs={12} sm={8}>
        <Tooltip {...{ tooltip }} open={!!tooltip}>
          <SelectGroupedOptions
            value={voice}
            disabled={disabled}
            placeholder="select voice"
            startDecorator={<RecordVoiceOverIcon />}
            onChange={(e, value) => (setVoice(value))}

            sx={{ m: 2 }}
            options={voiceOptions}
          >
          </SelectGroupedOptions>
        </Tooltip>
      </Grid>
    </Grid>

  );

}