import React from 'react';
import Tooltip from '../common/Tooltip';
import CircularProgress from '@mui/joy/CircularProgress';
import PhoneIcon from '@mui/icons-material/Phone';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import SplitButton from '../common/SplitButton';


export default function AgentButton({ hasTelephony, hasWebRTC, tooltip, state, setRequestTelephony, onClick, ...rest }) {
  let options = [];
  
  if (state === 'initial') {
    hasWebRTC && options.push({
      startDecorator: <SpeakerPhoneIcon />,
      label: 'Talk to Agent',
      onClick: () => {
        console.log(`start WebRTC`);
        onClick(false);
      }
    });
    hasTelephony && options.push({
      startDecorator: <PhoneIcon />,
      label: 'Telephone Agent',
      onClick: () => {
        console.log('start Telephony');
        onClick(true);
      }
    });
    !options.length && options.push({ startDecorator: <SpeakerPhoneIcon />, label: 'Talk to Agent', onClick: onClick });
  } else if (state === 'trying') {
    options.push({ startDecorator: <CircularProgress thickness={2} /> });
  } else if (state === 'active') {
    options.push({ label: 'Update Agent', onClick: onClick });
  }

  console.log({ options, hasTelephony, hasWebRTC }, 'agent button');

  return (
    <Tooltip {...{ tooltip }} open={!!tooltip}>
      <SplitButton
        {...rest}
        options={options}
      />
    </Tooltip>
  );
};